<template>
	<div class="look-evidence">
		<!-- 顶部栏 -->
		<nav-bar></nav-bar>

		<!-- 内容 -->
		<div class="evidence-content">

			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>查看证据</el-breadcrumb-item>
			</el-breadcrumb>

			<div class="evidence-radio">
				<el-radio-group v-model="params.status" @input="changeRadio">
					<el-radio-button label="3">临时笔录</el-radio-button>
					<el-radio-button label="4">封存笔录</el-radio-button>
				</el-radio-group>
			</div>

			<div class="content-table" v-if="params.status == '3'">
				<el-table :data="tableData" border highlight-current-row>
					<el-table-column type="index" label="序号" align="center" width="90"></el-table-column>
					<el-table-column prop="case_classification" label="案件类别" align="center" width="200">
						<div slot-scope="scope">
							{{scope.row.classification.name}}
						</div>
					</el-table-column>
					<el-table-column prop="name" label="被询(讯)问人姓名" align="center" width="100"></el-table-column>
					<el-table-column prop="card_num" label="证件号码" align="center" width="190"></el-table-column>
					<el-table-column prop="num" label="次数" align="center" width="90"></el-table-column>
					<el-table-column prop="begin_time_text" label="开始时间" align="center" width="110"></el-table-column>
					<el-table-column prop="end_time_text" label="结束时间 " align="center" width="110"></el-table-column>
					<el-table-column prop="record_address" label="地点" align="center"></el-table-column>
					<el-table-column label="询(讯)问人1" align="center" width="100">
						<div slot-scope="scope">
							{{scope.row.police.nickname}}
						</div>
					</el-table-column>
					<el-table-column prop="name2" label="询(讯)问人2" align="center" width="100"></el-table-column>
					<el-table-column prop="name3" label="询(讯)问人3" align="center" width="100"></el-table-column>
					<el-table-column fixed="right" align="center" label="操作" min-width="180">
						<template slot-scope="scope">
							<el-button @click="clickPreview(scope.row)" type="text" size="small"
								style="color: #218CFF;">预览笔录</el-button>
							<el-button v-if="scope.row.evidence" @click="clickCvidence(scope.row)" type="text" size="small"
								style="color: #07CB42;">预览证据</el-button>
							<el-button v-if="scope.row.classification_user" @click="clickPrint(scope.row,3)" type="text" size="small"
								style="color: #07CB42;">预览告知书</el-button>
							<el-button @click="clickRedact(scope.row)" type="text" size="small"
								style="color: #344bcb;">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block_box">
					<el-pagination background @size-change="pageSizeChange" @current-change="pageCurrent"
						:current-page="params.page" :page-sizes="[10, 20, 30, 40]" :page-size="+params.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="listData.total_count">
					</el-pagination>
				</div>
			</div>

			<div class="content-table" v-if="params.status == '4'">
				<el-table :data="tableDataB" border highlight-current-row>
					<el-table-column type="index" label="序号" align="center" width="90"></el-table-column>
					<el-table-column prop="case_classification" label="案件类别" align="center" width="200">
						<div slot-scope="scope">
							{{scope.row.classification.name}}
						</div>
					</el-table-column>
					<el-table-column prop="name" label="被询(讯)问人姓名" align="center" width="100"></el-table-column>
					<el-table-column prop="card_num" label="证件号码" align="center" width="190"></el-table-column>
					<el-table-column prop="num" label="次数" align="center" width="90"></el-table-column>
					<el-table-column prop="begin_time_text" label="开始时间" align="center" width="110"></el-table-column>
					<el-table-column prop="end_time_text" label="结束时间 " align="center" width="110"></el-table-column>
					<el-table-column prop="record_address" label="地点" align="center"></el-table-column>
					<el-table-column label="询(讯)问人1" align="center" width="100">
						<div slot-scope="scope">
							{{scope.row.police.nickname}}
						</div>
					</el-table-column>
					<el-table-column prop="name2" label="询(讯)问人2" align="center" width="100"></el-table-column>
					<el-table-column prop="name3" label="询(讯)问人3" align="center" width="100"></el-table-column>
					<el-table-column fixed="right" align="center" label="操作" width="180">
						<template slot-scope="scope">
							<el-button @click="clickDel(scope.row)" type="text" size="small"
								style="color: #07CB42;">删除</el-button>
							<!-- <el-button @click="clickPrint(scope.row)" type="text" size="small"
								style="color: #344bcb;">打印</el-button> -->

						</template>
					</el-table-column>
				</el-table>
				<div class="block_box">
					<el-pagination background @size-change="pageSizeChange" @current-change="pageCurrent"
						:current-page="params.page" :page-sizes="[10, 20, 30, 40]" :page-size="+params.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="listData.total_count">
					</el-pagination>
				</div>
			</div>

		</div>

		<!-- 预览/修改 笔录 -->
		<!-- 预览笔录 -->
		<el-dialog title="笔录预览" :visible.sync="previewPdfImgShow" center width="900px" :show-close="false">
			<div style="width: 100%;max-height: 600px;height: 600px;overflow-y: auto;">
				<div style="position: relative;" v-for="(img,index) in previewPdfImg.data" :key="img">
					<img style="width: 100%;object-fit: contain;" :src="$baseURL+'/'+img" alt="" />
					<div class="signature_box" v-if="index<previewPdfImg.data.length-1">
						<img class="signature_box_signature" v-if="previewPdfImg.num.sign_image"
							:src="$baseURL+previewPdfImg.num.sign_image" alt="" />
						<img class="signature_box_signature" v-if="previewPdfImg.num.date_image"
							:src="$baseURL+previewPdfImg.num.date_image" alt="" />
					</div>
					<div style="position: absolute;bottom:10px;right:80px;">
						第{{index+1}}页 共{{previewPdfImg.data.length}}页
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
				<custom-button confirmText="确定" type="confirm"
					@click.native="previewPdfImgShow = false"></custom-button>
				<custom-button style="margin-left: 20px;" confirmText="打印" type="confirm"
					@click.native="clickPrint(putDownData,1)"></custom-button>
			</div>
		</el-dialog>


		<!-- 预览证据 -->
		<el-dialog title="证据预览" :visible.sync="previewShow" width="70%">
			<div v-if="evidenceDetail.file.length>0"
				style="width: 100%;height: 100%;max-height: 700px; padding-left: 50px;overflow-y: auto;text-align: center;">
				<div style="position: relative;" v-for="item in evidenceDetail.file" :key="item">
					<img :src="$baseURL+'/'+item" alt="" />
				</div>
				<div style="text-align: center;">
					<img v-if="$baseURL+evidenceDetail.confirm_image"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image" alt="" />
					<img v-if="$baseURL+evidenceDetail.confirm_image2"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image2" alt="" />
					<img v-if="$baseURL+evidenceDetail.confirm_image3"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image3" alt="" />
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img class="signature" v-if="evidenceDetail.sign_image" :src="$baseURL+evidenceDetail.sign_image"
						alt="" />
					<img class="signature" v-if="evidenceDetail.date_image" :src="$baseURL+evidenceDetail.date_image"
						alt="" />
				</div>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
				<custom-button type="confirm" confirmText="确定" @click.native="previewShow = false"
					style="margin-left: .975rem;"></custom-button>
				<custom-button type="confirm" confirmText="打印" @click.native="clickPrint(putDownData,2)"
					style="margin-left: .975rem;"></custom-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import NavBar from "../components/NavBar.vue";
	import previewPutDown from "../components/previewPutDown.vue";
	export default {
		components: {
			'nav-bar': NavBar,
			'preview-put-down': previewPutDown,
		},
		data() {
			return {
				params: {
					page: 1,
					limit: 10,
					status: '3',
				},
				putDownData: {},
				listData: {},
				tableData: [],
				tableDataB: [],
				previewShow: false,
				evidenceDetail: {
					file: [],
				},
				// 预览笔录
				previewPdfImgShow: false,
				previewPdfImg: {
					data: [],
					num: {}
				},
				// 合成图片
				combinedImageUrl: null,
			}
		},
		created() {
			this.getList()
		},
		methods: {
			changeRadio(e) {
				console.log(e);
				this.params.page = 1
				this.params.limit = 10
				this.params.status = e
				this.getList()
			},
			// 获取笔录列表
			getList() {
				this.$api.post('/police/case_data/case_num_list', this.params)
					.then(res => {
						console.log("笔录列表" + this.params.status, res)
						if (res.code == 1) {
							if (this.params.status == 3) {
								this.tableData = res.data.list
							} else {
								this.tableDataB = res.data.list
							}
							this.listData = res.data
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, "error")
					})
			},
			// 点击预览笔录
			clickPreview(row) {
				console.log("row", row)
				this.putDownData = row
				// this.$refs.previewPutDown.show()
				window.open('https://c.ynshedingqiang.cn/index/index/num2?num_id=' + row.id)

				// this.clickNumView(row.id)
			},
			// 预览笔录
			clickNumView(id) {
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				this.$api.post('/api/index/num_view', {
						num_id: id,
						fresh: 1,
					}).then(res => {
						console.log("预览笔录", res)
						if (res.code == 1) {
							this.previewPdfImg = res.data
							this.previewPdfImgShow = true
						} else {
							this.$use.msg(res.msg, 'error');
						}
						loading.close();
					})
					.catch(err => {
						loading.close();
						this.$use.msg(err.msg || '获取笔录信息失败', 'error');
					})
			},
			// 打印笔录
			clickPrint(row, type) {
				if (type == 1) {
					window.open('https://c.ynshedingqiang.cn/index/index/num2?num_id=' + row.id)
				} else if (type == 2) {
					window.open('https://m.ynshedingqiang.cn/proof.html?num_id=' + row.id)
				} else if (type == 3) {
					window.open('https://m.ynshedingqiang.cn/notification.html?num_id=' + row.id)
				}
			},
			// 点击预览证据
			clickCvidence(row) {
				this.$api.post('/police/case_data/case_evidence_view', {
						num_id: row.id
					}).then(res => {
						if (res.code == 1) {
							console.log("预览证据", res)
							// window.open(this.$baseURL+res.data.file)
							this.evidenceDetail = res.data
							this.previewShow = true
						} else {
							this.$use.msg(res.msg, "error")
						}
					})
					.catch(err => {
						console.log(111)
						this.$use.msg(err.msg, "error")
					})
			},
			// 点击编辑
			clickRedact(row) {
				this.$router.push({
					path: '/recordAdd?id='+row.id,
					query:row.id,
				})
			},
			// 点击删除封存笔录
			clickDel(row) {
				this.$api.post('/police/case_data/case_num_del', {
						num_id: row.id
					}).then(res => {
						if (res.code == 1) {
							this.$use.msg('删除成功', "success")
							this.getList()
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, "error")
					})
			},
			// 更新页数
			pageSizeChange(e) {
				console.log("条数", e)
				this.params.limit = e
				this.getList()
			},
			// 更新页码
			pageCurrent(e) {
				console.log("页码", e)
				this.params.page = e
				this.getList()
			},
		}
	}
</script>
<style lang="scss">
	.look-evidence {
		background: #F8F8F8;

		.signature {
			max-width: 200px;
			height: 100px;
			object-fit: contain;
			transform: translate(-50%, -50%) rotate(-90deg);
		}

		.evidence-content {
			margin: .625rem auto 0;
			width: 20.5625rem;

			.evidence-radio {
				margin: .625rem auto 0;
			}

			.el-table th {
				color: #333333;
				background-color: #CCE5FF;
			}
		}

		.block_box {
			margin-top: 20px;
			padding-bottom: 50px;
			width: 100%;
			text-align: right;
		}

		.signature_box {
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translate(-50%, 20%);
		}

		.signature_box_signature {
			width: 80px;
			height: 120px;
			object-fit: contain;
			transform: translate(-0%, 0%) rotate(-90deg);
			margin: 0 30px;
		}
	}
</style>