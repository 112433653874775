<template>
	<!-- 提示  询问笔录-->
	<div class="put_down_custom-tips">
		<el-dialog title="询问笔录" :visible.sync="dialogVisible" center width="50%" :show-close="false">
			<div class="tips-content">
				<div class="right">第<span>&nbsp;{{putDownData.num}}&nbsp;</span>次</div>
				<!-- 内容 -->
				<div class="content_item">
					<div class="content_item_title">时间<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.begin_time_text||'无'}}
					</div>
					<div>至</div>
					<div class="content_item_content">
						{{putDownData.end_time_text||'无'}}
					</div>
				</div>
				<div class="content_item">
					<div class="content_item_title">地点<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.record_address||'无'}}
					</div>
				</div>
				<div class="content_item" v-for="item in putDownData.police_ids_list" :key="item.nickname">
					<div class="content_item_title">询问人<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{item.nickname||'无'}}
					</div>
					<div class="content_item_title2">
						工作单位
					</div>
					<div class="content_item_content">
						{{item.work_address||'无'}}
					</div>
				</div>
				<!-- <div class="content_item">
					<div class="content_item_title">记录人<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						无
					</div>
					<div class="content_item_title2">工作单位</p>
					</div>
					<div class="content_item_content">
						无
					</div>
				</div> -->
				<div class="content_item">
					<div class="content_item_title">被询问人<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.name||""}}
					</div>
					<div class="content_item_title2">曾用名
					</div>
					<div class="content_item_content">
						无
					</div>
				</div>
				<div class="content_item">
					<div class="m_r10">
						性别
					</div>
					<div class="content_item_content">
						{{putDownData.gender||""}}
					</div>
					<div class="content_item_title2">
						出生日期
					</div>
					<div class="content_item_content">
						{{putDownData.birthday||"无"}}
					</div>
					<div class="content_item_title2">
						文化程度
					</div>
					<div class="content_item_content">
						{{putDownData.degree||'无'}}
					</div>
				</div>
				<div class="content_item">
					<div class="m_r10">
						国籍
					</div>
					<div class="content_item_content">
						{{putDownData.nation||''}}
					</div>
					<div class="content_item_title2">
						民族
					</div>
					<div class="content_item_content">
						{{putDownData.nationality||"无"}}
					</div>
				</div>
				<div class="content_item">
					<div class="content_item_title">户籍所在地<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.register_address||'无'}}
					</div>
				</div>
				<div class="content_item">
					<div class="content_item_title">现住址<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.live_address||'无'}}
					</div>
				</div>
				<div class="content_item">
					<div class="m_r10">
						被询问人身份证件名称及号码
					</div>
					<div class="content_item_content">
						{{putDownData.card_type||""}} {{putDownData.card_num||"无"}}
					</div>
				</div>
				<div class="content_item">
					<div class="content_item_title">工作单位<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.work_address||'无'}}
					</div>
				</div>
				<div class="content_item">
					<div class="content_item_title">联系电话<p class="content_item_title_box"></p>
					</div>
					<div class="content_item_content">
						{{putDownData.mobile||'无'}}
					</div>
				</div>
				<div v-for="(item,index) in putDownData.question" :key="index">
					<div class="content_dialogue" v-if="index != putDownData.question.length-1">
						<div class="m_r10">
							{{item.type==0?'问':'答'}}:
						</div>
						<div class="underline">
							{{item.content}}
						</div>
					</div>
					<div class="content_dialogue" v-else-if="index == putDownData.question.length-1&&item.content!=''">
						<div class="m_r10">
							{{item.type==0?'问':'答'}}:
						</div>
						<div class="underline">
							{{item.content}}
						</div>
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img v-if="putDownData.confirm_image" class="confirm_img_item"
						:src="$baseURL+putDownData.confirm_image" alt="">
					<img v-if="putDownData.confirm_image2" class="confirm_img_item"
						:src="$baseURL+putDownData.confirm_image2" alt="">
						<img v-if="putDownData.confirm_image3" class="confirm_img_item"
							:src="$baseURL+putDownData.confirm_image3" alt="">
				</div>
				
				<div class="" >
					<div></div>
					<div style="display: flex;align-items: center;justify-content: flex-end;margin-right: 200px;">
						<div class="signature_content">
							<div class="signature_content_title">被询问人：</div>
							<img v-if="putDownData.sign_image" class="signature" :src="$baseURL+putDownData.sign_image"
								alt="" />
						</div>
						<div class="signature_content">
							<div>
								<div>询问人：</div>
								<div>记录人：</div>
							</div>
							<div v-if="putDownData.police_image">
								<img class="signature" :src="$baseURL+putDownData.police_image" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="">
					<div class="signature_content">
						<div class="signature_content_title">日期 ：</div>
						<img v-if="putDownData.date_image" class="signature" :src="$baseURL+putDownData.date_image"
							alt="" />
					</div>
					<div>

					</div>
				</div>
				<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
					<custom-button confirmText="确定" type="confirm"
						@click.native="dialogVisible = false"></custom-button>
					<custom-button style="margin-left: 20px;" confirmText="发起签名" type="confirm"
						@click.native="clickSave()"></custom-button>
					<custom-button style="margin-left: 20px;" confirmText="打印笔录" type="confirm"
						@click.native="clickPreview()"></custom-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'previewPutDown',
		props: ['putDownData'],
		data() {
			return {
				value: '',
				input: '',
				searchVal: '',
				dialogVisible: false
			}
		},
		methods: {
			show() {
				this.dialogVisible = true;
			},
			close() {
				this.dialogVisible = false;
			},
			clickSave(){
				this.$emit('clickSave',2)
			},
			clickPreview(){
				this.$emit('clickPreview')
			}
		}
	}
</script>
<style lang="scss">
	.put_down_custom-tips {

		.signature {
			max-width: 200px;
			height: 100px;
			object-fit: contain;
		}

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.el-dialog__headerbtn .el-dialog__close {
			font-size: .45rem;
			color: #909399;
		}

		.tips-content {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 600px;
			overflow: hidden;
			overflow-y: auto;
			box-sizing: border-box;
			padding-right: 10px;
		}


		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.right {
			text-align: right;
		}

		.underline {
			width: 100%;
			text-decoration: underline;
			position: relative;
			display: inline-block;
			box-sizing: border-box;
		}

		.underline:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: currentColor;
			bottom: 2px;

		}

		.bor_s_3 {
			border-bottom: 1px solid #333;
		}

		.content_item {
			display: flex;
			align-items: flex-end;
			margin-top: 10px;
		}

		.content_item_title {
			width: 80px;
			text-align: justify;
			list-style: none;
			height: 20px;
			margin-right: 10px;
		}

		.content_item_title2 {
			margin: 0 10px;
		}

		.content_item_title_box {
			display: inline-block;
			width: 100%;
		}

		.content_item_content {
			flex: 1;
			width: 200px;
			border-bottom: 1px solid #000;
		}

		.m_r10 {
			margin-right: 10px;
		}

		.content_dialogue {
			display: flex;
			align-items: flex-start;
			margin-top: 10px;
		}

		.signature_box {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
		}

		.signature_content {
			display: flex;
			align-items: center;
			margin-left: 90px;
		}

		.signature_content_title {}

		.signature_content_img {
			width: 80px;
			height: 40px;
		}

		.confirm_img_item {
			max-width: 200px;
			height: 200px;
			object-fit: contain;
			margin-right: 85px;
			transform: translate(-50%) rotate(-90deg);
		}

	}
</style>