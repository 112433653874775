<template>
	<div class="home">
		<!-- 顶部栏 -->
		<nav-bar></nav-bar>
		<!-- 内容 -->
		<div class="home-content">
			<div class="home-upload">
				<el-upload class="upload-content" :on-success="fileSuccess" drag
					action="https://c.ynshedingqiang.cn/api/common/upload" multiple>
					<!-- <el-upload class="upload-content" drag :action="`${$baseURL}/api/common/upload`" multiple> -->
					<img class="img-upload" src="../assets/images/home_upload.png" alt="">
					<div class="upload-title">
						<span>拖拽文档到此区域/</span>
						<span>上传文档发起笔录制作</span>
					</div>
					<div class="upload-tips">支持格式：word，文档大小不超过100MB</div>
				</el-upload>
			</div>
			

			<!-- 菜单 -->
			<div class="home-menu">
				<div v-for="(item, index) in menuList" :key="index" @click="toMenu(item)">
					<img :src="item.icon" alt="">
					<span>{{ item.name }}</span>
				</div>
			</div>

			<!-- 广告图 -->
			<div class="home-ad">
				<img src="../assets/images/home_ad.png" alt="">
			</div>

		</div>

		<!-- 底部 -->
		<div class="home-footer">
			<div>
				<span>Copyright 2016-2020 baodaren.All rights reserved 豫ICP备16015854号-2 增值电信业务经营许可证豫B2-20200403
					在线数据处理与交易许可证豫B2-20200403</span>
				<span>版权所有：昆明东远科技有限公司</span>
				<span>Poweredby baodaren1.0</span>
			</div>
			<div>
				<div v-for="(item, index) in 5" :key="index">
					<i class="el-icon-picture-outline"></i>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import NavBar from "../components/NavBar.vue";
	export default {
		components: {
			'nav-bar': NavBar,
		},
		data() {
			return {
				menuList: [{
					icon: require('../assets/images/home_icon01.png'),
					name: '远程取证',
					url: '/recordAdd'
				}, {
					icon: require('../assets/images/home_icon02.png'),
					name: '证据归档',
					url: '/lookEvidence'
				}, {
					icon: require('../assets/images/home_icon03.png'),
					name: '执法指引'
				}, {
					icon: require('../assets/images/home_icon04.png'),
					name: '拍立传'
				}, {
					icon: require('../assets/images/home_icon05.png'),
					name: '文字识别'
				}, {
					icon: require('../assets/images/home_icon06.png'),
					name: '警务AI'
				}, {
					icon: require('../assets/images/home_icon07.png'),
					name: '法律法规检索'
				}, {
					icon: require('../assets/images/home_icon08.png'),
					name: '司法案例检索'
				}, {
					icon: require('../assets/images/home_icon09.png'),
					name: '在线考试'
				}]
			}
		},
		created() {
			this.getBanner();
		},
		methods: {
			async getBanner() {
				// const res = await this.$api.post('/api/config/banner');
				// if (res.code == 1) {
				//   this.banner = res.data.map(item => this.$use.loadImg(item.image));
				// } else {
				//   this.$use.msg(res.msg)
				// }
			},
			toMenu(item) {
				if (item.url) {
					this.$router.push({
						path: item.url
					})
				} else {
					this.$message({
						message: '暂未开放',
						type: 'warning'
					});
				}
			},
			// 上传图片
			fileSuccess(response, file, fileList) {
				console.log(response, file, fileList)
			},
			


		}
	}
</script>
<style lang="scss">
	.home {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: #F8F8F8;
		width: 100%;
		min-height: 100vh;
		max-height: 100%;

		.home-footer {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 4.875rem;
			background: #218CFF;
			margin-top: 1.225rem;

			>div {
				&:first-child {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-weight: bold;
					font-size: .2rem;
					color: #FFFFFF;

					>span {
						margin: .125rem 0;
					}
				}

				&:last-child {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: .5rem;

					>div {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 2.225rem;
						height: .925rem;
						background: #FFFFFF;
						border-radius: .125rem;
						margin: 0 .375rem;
					}
				}
			}
		}

		.home-content {
			flex: 1;
			width: 17.25rem;
			margin: .625rem auto 0;

			.home-ad {
				width: 100%;
				height: 3.75rem;
				border-radius: .25rem;

				>img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			.home-menu {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				margin: .375rem auto 0;

				>div:hover {
					transform: scale(1.1);
				}

				>div {
					display: flex;
					align-items: center;
					padding-left: 1.125rem;
					width: 5.475rem;
					height: 2.625rem;
					background: #FFFFFF;
					border-radius: .25rem;
					margin-bottom: .325rem;
					cursor: pointer;
					transition: transform 0.3s ease;

					>img {
						display: block;
						width: .95rem;
						height: .875rem;
						margin-right: .25rem;
					}

					>span {
						font-weight: bold;
						font-size: .375rem;
						color: #333333;
					}
				}
			}


			.home-upload {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 5.225rem;
				background: #FFFFFF;
				border-radius: .25rem;
				// overflow: hidden;

				.img-upload {
					width: 2.2125rem;
					height: 1.6rem;
				}

				.upload-title {
					margin: .25rem 0;

					>span {
						font-size: .325rem;
						font-weight: bold;

						&:first-child {
							color: #333333;
						}

						&:last-child {
							color: #FF1A1A;
						}
					}
				}

				.el-upload-dragger {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 17.25rem;
					height: 5.225rem;
					border: none !important;
				}

				.upload-tips {
					font-weight: bold;
					font-size: .25rem;
					color: #333333;
				}
			}
		}
	}
</style>